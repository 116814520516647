import { FC, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { createSubscription, deleteSubscription, updateSubscription } from "src/api/subscriptions.ts";
import { CompanyDropdown } from "src/components/companyDropdown.tsx";
import { ConfirmModal } from "src/components/confirmModal.tsx";
import { CustomerDropdown } from "src/components/customerDropdown.tsx";
import { ShippingLine } from "src/model/shippingLine.ts";
import { SimpleSubscription } from "src/model/subscription.ts";
import { SubscriptionUpdateRequest } from "src/model/subscriptionSaveRequest.ts";
import { useToasts, useUserState } from "src/store/store.ts";
import { nullIfEmpty } from "src/utils/strings.ts";

interface MetaDataFormData {
  externalReference?: string;
  containerNumberIncludeList: string;
  shippersReference?: string;
}

interface SubscriptionFormData {
  customerId?: string;
  companyId?: string;
  billOfLading?: string;
  bookingNumber?: string;
  shippingLine: string;
  metaData: MetaDataFormData[];
}

interface SubscriptionFormProps {
  subscription: SimpleSubscription | null;
  shippingLines: ShippingLine[];
}

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ subscription, shippingLines }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SubscriptionFormData>({
    defaultValues: {
      bookingNumber: subscription?.bookingNumber ?? undefined,
      billOfLading: subscription?.billOfLading ?? undefined,
      shippingLine: subscription?.scac,
      metaData: subscription?.metaData.map((md) => ({
        shippersReference: md.shippersReference ?? undefined,
        externalReference: md.externalReference ?? undefined,
        containerNumberIncludeList: md.containerNumberIncludeList.join(", "),
      })),
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: "metaData" });
  const selectedCustomerId = watch("customerId");
  const { showToast } = useToasts();
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const user = useUserState();

  const onSubmit = async (subscriptionFormData: SubscriptionFormData) => {
    try {
      const subscriptionUpdateRequest: SubscriptionUpdateRequest = {
        active: true,
        bookingNumber: nullIfEmpty(subscriptionFormData.bookingNumber),
        billOfLading: nullIfEmpty(subscriptionFormData.billOfLading),
        scac: subscriptionFormData.shippingLine,
        metaData: subscriptionFormData.metaData
          .map((md) => ({
            shippersReference: nullIfEmpty(md.shippersReference),
            externalReference: nullIfEmpty(md.externalReference),
            containerNumberIncludeList: md.containerNumberIncludeList.split(",").map((cn) => cn.trim()),
          }))
          .filter(
            (md) =>
              md.externalReference !== null || md.shippersReference !== null || md.containerNumberIncludeList.length > 0
          ),
      };

      if (subscription === null) {
        const customerId = subscriptionFormData.companyId ?? subscriptionFormData.customerId;
        if (customerId === undefined) {
          showToast("Missing customer id.");
          return;
        }
        await createSubscription({ ...subscriptionUpdateRequest, customerId: customerId });
      } else {
        await updateSubscription(subscription.id, subscriptionUpdateRequest);
      }

      showToast("Subscription successfully saved.");

      navigate("/subscriptions");
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDeletion = async () => {
    if (subscription === null) {
      return;
    }
    await deleteSubscription(subscription.id);
    showToast("Subscription successfully deleted.");
    navigate("/subscriptions");
  };

  const getContainerNumberIncludeListError = (index: number): string | undefined => {
    if (errors.metaData !== undefined) {
      const md = errors.metaData[index];
      return md?.containerNumberIncludeList?.message;
    }
  };

  return (
    <Row>
      <Col xs={12} sm={10} md={8} xl={6} className="m-auto mt-5">
        <ConfirmModal
          title="Delete subscription"
          body="Do you really want to delete subscription?"
          show={showDeleteConfirmation}
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          handleConfirm={confirmDeletion}
          handleClose={() => setShowDeleteConfirmation(false)}
        />
        <div className="bg-white shadow p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {subscription === null && (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={4}>
                    Customer
                  </Form.Label>
                  <Col sm={8}>
                    <Controller
                      control={control}
                      name="customerId"
                      rules={{ required: "This field is required." }}
                      render={({ field: { value, onChange } }) => (
                        <CustomerDropdown
                          customerId={value}
                          onChangeCustomerId={onChange}
                          allowDeselect={true}
                          className={errors.customerId?.message !== undefined ? "is-invalid" : undefined}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                      {errors.customerId?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={4}>
                    Company
                  </Form.Label>
                  <Col sm={8}>
                    <Controller
                      control={control}
                      name="companyId"
                      render={({ field: { value, onChange } }) => (
                        <CompanyDropdown
                          customerId={selectedCustomerId}
                          companyId={value}
                          onChangeCompanyId={onChange}
                          className={errors.customerId?.message !== undefined ? "is-invalid" : undefined}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                      {errors.customerId?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </>
            )}

            <Form.Group as={Row} className="mb-3" controlId="mbl">
              <Form.Label column sm={4}>
                Bill of lading
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder="Bill of lading"
                  {...register("billOfLading", {
                    setValueAs: (value) => value || undefined,
                    validate: {
                      mblOrBn: (value, formValues) =>
                        (value && value.length > 0) || (formValues.bookingNumber && formValues.bookingNumber.length > 0)
                          ? true
                          : "Either bill of lading or booking number is required.",
                    },
                  })}
                  isInvalid={errors.billOfLading?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.billOfLading?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="bn">
              <Form.Label column sm={4}>
                Booking number
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder="Booking number"
                  {...register("bookingNumber", {
                    setValueAs: (value) => value || undefined,
                    validate: {
                      mblOrBn: (value, formValues) =>
                        (value && value.length > 0) || (formValues.billOfLading && formValues.billOfLading.length > 0)
                          ? true
                          : "Either bill of lading or booking number is required.",
                    },
                  })}
                  isInvalid={errors.bookingNumber?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.bookingNumber?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="shippingLine">
              <Form.Label column sm={4}>
                Shippingline
              </Form.Label>
              <Col sm={8}>
                <Form.Select
                  disabled={shippingLines.length === 0}
                  {...register("shippingLine", { required: "This field is required." })}
                  isInvalid={errors.shippingLine?.message !== undefined}
                >
                  {shippingLines.length === 0 ? (
                    <option value="">Loading ...</option>
                  ) : (
                    <>
                      <option value="">Select a shippingline</option>
                      {shippingLines
                        .sort((sl1, sl2) => (sl1.name > sl2.name ? 1 : -1))
                        .map((sl) => (
                          <option value={sl.scac} key={sl.scac}>
                            {sl.name} ({sl.scac})
                          </option>
                        ))}
                    </>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.shippingLine?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Col sm={4} className="my-2">
                Meta Datas
              </Col>
              <Col sm={8}>
                <Row className="my-2">
                  <Col sm={4}>External Reference</Col>
                  <Col sm={4}>Container Numbers</Col>
                  <Col sm={3}>Shippers Reference</Col>
                  <Col className="d-flex align-items-center justify-content-center">
                    <PlusCircle onClick={() => append({ containerNumberIncludeList: "" })} />
                  </Col>
                </Row>
                {fields.map((field, index) => (
                  <Row key={field.id} className="my-2">
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        placeholder="External Reference"
                        {...register(`metaData.${index}.externalReference`)}
                        disabled={true}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        placeholder="Container Numbers"
                        {...register(`metaData.${index}.containerNumberIncludeList`, {
                          required: "This field is required.",
                        })}
                        isInvalid={getContainerNumberIncludeListError(index) !== undefined}
                        disabled={field.externalReference !== undefined}
                      />
                      <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                        {getContainerNumberIncludeListError(index)}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        type="text"
                        placeholder="Shippers Reference"
                        {...register(`metaData.${index}.shippersReference`)}
                        disabled={field.externalReference !== undefined}
                      />
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center">
                      {field.externalReference === undefined && <DashCircle onClick={() => remove(index)} />}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Link to="/subscriptions" className="btn btn-outline-primary">
                Cancel
              </Link>
              <div className="flex-grow-1" />
              {user.role === "Admin" && subscription?.id && (
                <Button variant="danger" type="button" onClick={() => setShowDeleteConfirmation(true)} className="me-3">
                  Delete
                </Button>
              )}
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
