import { getClient } from "src/api/api.ts";
import { Customer, CustomerSaveRequest, CustomerSortBy } from "src/model/customer";
import { PageAndSortRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { SubscriptionStats } from "src/model/subscriptionStats";

export const fetchCustomers = async (
  pagination: PageAndSortRequest<CustomerSortBy>,
  onlyActive: boolean | undefined = undefined
): Promise<PaginatedResponse<Customer>> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Customers", {
    params: {
      query: {
        onlyActive: onlyActive,
        limit: pagination.limit,
        offset: pagination.offset,
        sortBy: pagination.sortBy,
        sortDir: pagination.sortDir,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch customers");
};

export const fetchCompanies = async (
  customerId: string,
  onlyActive: boolean | undefined = undefined
): Promise<Customer[]> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Customers/{customerId}/Companies", {
    params: {
      path: {
        customerId: customerId,
      },
      query: {
        onlyActive: onlyActive,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch companies");
};

export const fetchCustomerSubscriptionStats = async (customerId: string): Promise<SubscriptionStats> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Customers/{customerId}/Subscriptions/Stats", {
    params: {
      path: {
        customerId: customerId,
      },
    },
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch companies");
};

export const fetchCustomerDetails = async (customerId: string): Promise<Customer> => {
  const { data, error } = await getClient().GET("/API/iSEA5/V2.1/Customers/{customerId}", {
    params: {
      path: {
        customerId: customerId,
      },
    },
  });
  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to fetch customer");
};

export const createCompany = async (
  customerId: string,
  customerSaveRequest: CustomerSaveRequest
): Promise<Customer> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Customers/{customerId}/Companies", {
    params: {
      path: {
        customerId: customerId,
      },
    },
    body: customerSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to create customer");
};

export const createCustomer = async (customerSaveRequest: CustomerSaveRequest): Promise<Customer> => {
  const { data, error } = await getClient().POST("/API/iSEA5/V2.1/Customers", {
    body: customerSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to create customer");
};

export const updateCustomer = async (
  customerId: string,
  customerSaveRequest: CustomerSaveRequest
): Promise<Customer> => {
  const { data, error } = await getClient().PUT("/API/iSEA5/V2.1/Customers/{customerId}", {
    params: {
      path: {
        customerId: customerId,
      },
    },
    body: customerSaveRequest,
  });

  if (data !== undefined) {
    return data;
  }
  if (error !== undefined && error.statusCode === 503 && error.message !== null) {
    throw new Error(error.message);
  }
  throw new Error("Failed to update customer");
};
