import { FC, useEffect, useMemo, useState } from "react";
import { Fade, Spinner, Table } from "react-bootstrap";
import { Check, Pencil, PlusCircle, Trash, X } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { deleteUser, fetchUsers } from "src/api/user.ts";
import { ConfirmModal } from "src/components/confirmModal.tsx";
import { Pagination } from "src/components/pagination.tsx";
import { PageRequest } from "src/model/pageRequest.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { User } from "src/model/user.ts";
import { useAuthState, useToasts } from "src/store/store.ts";
import { parseSearchParamToNumber, useSearchParams } from "src/utils/searchParams.ts";

import styles from "./customerList.module.scss";

const defaultPagination: PageRequest = {
  limit: 10,
  offset: 0,
};

export const UserList: FC = () => {
  const [users, setUsers] = useState<PaginatedResponse<User> | undefined>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseSearchParamToNumber(searchParams.get("offset")) ?? defaultPagination.offset;
  const limit = defaultPagination.limit;
  const auth = useAuthState();
  const { showToast } = useToasts();

  const setOffset = useMemo(
    () => (offset: number) => {
      setSearchParams((prev) => {
        prev.set("offset", offset.toString());
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.size === 0) {
          Object.entries(defaultPagination).forEach(([key, value]) => {
            prev.set(key, value);
          });
        }
        return prev;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  useEffect(() => {
    setLoading(true);
    fetchUsers({ limit: limit, offset: offset })
      .then((result) => setUsers(result))
      .finally(() => setLoading(false));
  }, [offset, limit]);

  const confirmDeletion = async () => {
    if (userToDelete === undefined) {
      return;
    }
    await deleteUser(userToDelete.id);
    showToast("User successfully deleted.");
    setUserToDelete(undefined);
    if (users && users.totalItems > 1) {
      setOffset(offset);
    } else if (offset > 0) {
      setOffset(offset - limit);
    } else {
      setOffset(0);
    }
  };

  const onPageChange = (offset: number) => {
    setOffset(offset);
  };

  if (users === undefined) {
    return (
      <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
        <div className={`w-100 h-100 ${styles.fade}`}></div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Fade in={loading} unmountOnExit={true} mountOnEnter={true}>
        <div className={`d-flex justify-content-center align-items-center ${styles.block}`}>
          <div className={`w-100 h-100 ${styles.fade}`}></div>
          <Spinner />
        </div>
      </Fade>
      <ConfirmModal
        title={`Delete user ${userToDelete?.userName}`}
        body={`Do you really want to delete user '${userToDelete?.userName}'?`}
        show={userToDelete !== undefined}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        handleConfirm={confirmDeletion}
        handleClose={() => setUserToDelete(undefined)}
      />
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Active</th>
            <th>Mail</th>
            <th>Role</th>
            <th>Rights</th>
            <th>Customer</th>
            <th>Company</th>
            <th style={{ width: "64px" }} className="text-end">
              <Link to="/users/new" className="text-black">
                <PlusCircle size={18} />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.items.map((u) => (
            <tr key={`user-${u.id}`}>
              <td>{u.userName}</td>
              <td>{u.active ? <Check size={24} /> : <X size={24} />}</td>
              <td>{u.mail}</td>
              <td>{u.userRole}</td>
              <td>{u.userRights.join(", ")}</td>
              <td>{u.customerName}</td>
              <td>{u.companyName}</td>
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  <Link to={`/users/${u.id}`} className="text-black">
                    <Pencil size={18} />
                  </Link>
                  {auth.userId !== u.id ? (
                    <span role="button" onClick={() => setUserToDelete(u)}>
                      <Trash size={18} />
                    </span>
                  ) : (
                    <span className="text-secondary">
                      <Trash size={18} />
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalItems={users.totalItems} offset={offset} limit={limit} onPageChange={onPageChange} />
    </>
  );
};
