import { FC, useActionState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { HttpError } from "src/api/httpError.ts";
import { fetchOwnUserDetails, login } from "src/api/user.ts";
import { setAuth, setPreferences, setUser } from "src/store/auth.ts";
import { useAppDispatch } from "src/store/store.ts";

export const LoginPage: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (_: null | string, loginFormData: FormData): Promise<null | string> => {
    const email = loginFormData.get("email") as string;
    const password = loginFormData.get("password") as string;

    try {
      const loginResponse = await login(email, password);
      dispatch(setAuth(loginResponse));
      const details = await fetchOwnUserDetails();
      dispatch(setUser(details));
      if (details.preferences !== null) {
        dispatch(setPreferences(details.preferences));
      }
      if (location.search.indexOf("backurl") >= 0) {
        const matches = location.search.match("backurl=([^&]*)");
        if (matches !== null && matches[1] !== undefined) {
          navigate(decodeURIComponent(matches[1]));
        } else {
          navigate("/");
        }
      } else {
        console.log("Navigating to /");
        navigate("/");
      }
    } catch (error) {
      if (error instanceof HttpError) {
        if (error.statusCode === 400) {
          return error.message;
        } else if (error.statusCode === 403) {
          return "Wrong username or password.";
        }
      } else {
        console.error(error);
      }
    }
    return null;
  };

  const [error, formAction, isPending] = useActionState(onSubmit, null);

  return (
    <>
      <Helmet>
        <title>iSEA Trace - Login</title>
      </Helmet>
      <Row>
        <Col xs={12} sm={8} md={6} xl={4} className="m-auto mt-5">
          <div className="bg-white shadow p-3">
            <form action={formAction}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label column="sm">Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name="email" required={true} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label column="sm">Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name="password" required={true} />
              </Form.Group>
              {error ? <div className="d-flex text-danger">{error}</div> : null}
              <div className="d-flex align-items-center justify-content-between">
                <Link to="/password-reset">Reset password</Link>
                <Button variant="primary" type="submit" disabled={isPending}>
                  Login
                </Button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
};
